import React, {useEffect, useState} from "react";
import './service-item.scss'
import {listServices} from "../../list-services";
import {doctors} from "../../doctors";

export const ServiceItem = ({match}) => {
    const [service, setService] = useState(null)
    const [serviceDoctors, setServiceDoctors] = useState([])

    const stringToLink = string => string.replace(/ /g, "_")

    useEffect(() => {
        listServices.forEach(serviceItem => {
            if (stringToLink(serviceItem.title.toLowerCase()) === match.params.service) setService(serviceItem)
        })
    }, [match])

    useEffect(() => {
        if (service && service.doctorsIds.length) {
            const matchedDoctors = []
            doctors.forEach(doctor => {
                service.doctorsIds.forEach(el => {
                    if (el === doctor.id) {
                        matchedDoctors.push(doctor)
                    }
                })
            })
            setServiceDoctors(matchedDoctors)
        }
    }, [service])

    return (
        <div className="service-item">
            <div className="service-item__header">
                <h1 className="service-item__title">{service && service.title}</h1>
                <p className="service-item__description">{service && service.description}</p>
            </div>
            <div className="service-item__list-wrapper">
                <div className='service-item__list-container'>
                    <h2 className="service-item__sub-title">Перечень услуг</h2>
                    <div className="service-item__list">
                        {service && service.extendedService.length ?
                            service.extendedService.map(el => (
                                <div className="service-item__item" key={el}>
                                    <img className="service-item__circle" src="assets/service-circle.svg" alt="Евролайф"/>
                                    <p className="service-item__item-title">{el}</p>
                                </div>
                            )) : null}
                    </div>
                </div>
            </div>
            <div className="service-item__doctors-list">
                {serviceDoctors.length > 0 && <h3 className="service-item__doctors-list-title">Лечащие врачи</h3>}
                {serviceDoctors.length > 0 && serviceDoctors.map(doctor => (
                    <div className="service-item__doctors-list-item" key={doctor.id}>
                        <img className="service-item__doctors-list-item-avatar" src={doctor.avatar} alt="Евролайф"/>
                        <div className="service-item__doctors-list-item-wrapper">
                            <h4 className="service-item__doctors-list-item-name">{doctor.name}</h4>
                            <p className="service-item__doctors-list-item-sign">Специальность</p>
                            <p className="service-item__doctors-list-item-specialization">{doctor.specialization}</p>
                            <p className="service-item__doctors-list-item-sign">Категория</p>
                            <p className="service-item__doctors-list-item-category">{doctor.category}</p>
                            <p className="service-item__doctors-list-item-sign">Опыт работы</p>
                            <p className="service-item__doctors-list-item-experience">{doctor.experience} лет</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}