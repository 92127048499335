import React from "react";
import './success-message.scss'
import {rusTexts} from "../../texts/rusTexts";
import {ukrTexts} from "../../texts/ukrTexts";

export const SuccessMessage = ({setModalWindowSuccess, language}) => {
    return (
        <div className="success-modal">
            <div className="success-modal__wrapper">
                <img onClick={() => setModalWindowSuccess(false)} className='success-modal__close-modal'
                     src="assets/close-btn.svg" alt="Евролайф"/>
                <h4 className="success-modal__title">{language === 'rus' ? rusTexts.signForm.success : ukrTexts.signForm.success}</h4>
                <p className="success-modal__sub-title">{language === 'rus' ? rusTexts.signForm.success2 : ukrTexts.signForm.success2}</p>
                <button className="success-modal__btn"
                        onClick={() => setModalWindowSuccess(false)}>{language === 'rus' ? rusTexts.signForm.returnBtn : ukrTexts.signForm.returnBtn}</button>
                <p className="success-modal__intermediate-text">{language === 'rus' ? rusTexts.signForm.text : ukrTexts.signForm.text}</p>
                <p className="success-modal__text">{language === 'rus' ? rusTexts.signForm.text2 : ukrTexts.signForm.text2}</p>
                <p className='contact-us-modal__phone'>+38 066 345 26 26 <br/> +38 096 345 26 26</p>
            </div>
        </div>
    )
}