import React from "react";
import './footer.scss'
import {NavLink} from "react-router-dom";
import {mapLocation} from "../../variables";
import {rusTexts} from "../../texts/rusTexts";
import {ukrTexts} from "../../texts/ukrTexts";
import Radium, {StyleRoot} from "radium";
import {fadeInUp} from "react-animations";

export const Footer = ({language}) => {
    const styles = {
        fadeInUp: {
            animation: 'x 1.3s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }
    return (
        <div className='footer'>
            <div className='footer__container'>
                <StyleRoot>
                    <NavLink to='/'>
                        <img style={styles.fadeInUp}
                             className="footer__logo wow" src="assets/logo.jpeg"
                             alt="Евролайф"/>
                    </NavLink>
                </StyleRoot>
                <StyleRoot>
                    <div className='footer__wrapper'>
                        <div className='footer__box'>
                            <h3 style={styles.fadeInUp} className='footer__title wow'>{language === 'rus' ? rusTexts.footer.title : ukrTexts.footer.title}</h3>
                            <div className='footer__sub-box'>
                                <div style={styles.fadeInUp} className='footer__icon-box wow'>
                                    <img className='footer__icon'
                                         src="/assets/phone-icon.svg"
                                         alt="Евролайф"/>
                                    <p className='footer__text'>+380
                                        66‒345‒26‒26</p>
                                </div>
                                <div style={styles.fadeInUp} className='footer__icon-box wow'>
                                    <img className='footer__icon'
                                         src="/assets/phone-icon.svg"
                                         alt="Евролайф"/>
                                    <p className='footer__text'>+380
                                        96‒345‒26‒26</p>
                                </div>
                            </div>
                            <div style={styles.fadeInUp} className='footer__sub-box wow'>
                                <a href={mapLocation} target="_blank"
                                   rel="noopener noreferrer"
                                   className='footer__icon-box'>
                                    <img className='footer__icon'
                                         src="/assets/location-icon-white.svg"
                                         alt="Евролайф"/>
                                    <p className='footer__text footer__text--link'>{language === 'rus' ? rusTexts.location : ukrTexts.location}</p>
                                </a>
                            </div>
                        </div>
                        <div style={styles.fadeInUp} className='footer__box wow'>
                            <h3 className='footer__title'>{language === 'rus' ? rusTexts.footer.text : ukrTexts.footer.text}</h3>
                            <div className='footer__sub-box'>
                                <p className='footer__text'
                                   style={{marginBottom: '10px'}}>ПН–ПТ:
                                    9:00–19:00</p>
                                <p className='footer__text'>СБ: 9:00–17:00</p>
                            </div>
                        </div>
                    </div>
                </StyleRoot>
            </div>
        </div>
    )
}