export const rusTexts = {
    header: {
        main: 'Главная',
        services: 'Направления',
        doctors: 'Врачи',
        contacts: 'Контакты',
        signUp: 'ЗАПИСАТЬСЯ'
    },
    location: 'Днепр, улица Паникахи, 27',
    mainHeader: {
        title: 'Индивидуальный подход к каждому пациенту',
        subTitle: 'Медицинский центр Евролайф ведет свою деятельность согласно мировым стандартам качества медицинских услуг.\n' +
            'К каждому клиенту специалисты медцентра находят свой подход.',
        signBtn: 'ЗАПИСАТЬСЯ НА ПРИЁМ',
        contentTitle1: 'Комфортная обстановка',
        contentTitle2: 'Опытные врачи',
        contentTitle3: 'Современное оборудование',
        contentText1: 'Уютная клиника и приветливый персонал помогут расслабиться и чувствовать себя комфортно при каждом посещении.',
        contentText2: 'Врачи высшей категории с вниманием и уважением относятся к каждому пациенту.',
        contentText3: 'Точная диагностика и лечение благодаря качественному оборудованию.',
    },
    mainServices: {
        title: 'Направления',
        subTitle: `В нашем центре вы сможете получить консультацию по интересующим
                    вопросам. Здесь вас обследуют и на основании этого назначат терапию. К каждому клиенту специалисты
                    медцентра Евролайф находят свой подход.`,
        showMore: 'ПОСМОТРЕТЬ ВСЕ УСЛУГИ'
    },
    mainDoctors: {
        title: 'Наша команда',
        subTitle: `Кадровый потенциал медицинского центра Евролайф представлен врачами и медицинскими сестрами первой и высшей категории, кандидатами медицинских наук, что позволяет оказывать квалифицированную диагностическую и лечебную помощь на самом высоком уровне.`,
        showMore: 'ПОСМОТРЕТЬ ВСЕХ ВРАЧЕЙ'
    },
    mainReviews: {
        title: 'Отзывы',
    },
    signForm: {
        nameInput: 'Имя',
        nameLabel: 'Введите свое имя',
        phoneInput: 'Номер телефона',
        serviceInput: 'Направление или врач',
        serviceLabel: 'Введите направление, услугу или фамилию врача',
        signBtn: 'ЗАПИСАТЬСЯ',
        title: 'Записаться на приём',
        subTitle: 'Оставьте свои данные и мы вам перезвоним',
        text: 'или',
        text2: 'позвоните нам сами',
        success: 'Ваш запрос отправлен!',
        success2: 'Мы свяжемся с вами в ближайшее время.',
        returnBtn: 'ВЕРНУТЬСЯ НА САЙТ',
    },
    services: {
        title: 'Направления',
        subTitle: 'Название направления',
        treatmentProcess: 'Как проходит процесс лечения',
        treatmentProcessSubTitle1: 'Сбор информации',
        treatmentProcessSubTitle2: 'Осмотр',
        treatmentProcessSubTitle3: 'Установление диагноза и назначение',
        treatmentProcessSubText1: 'Наши специалисты подходят к сбору информации с большой ответственностью',
        treatmentProcessSubText2: 'Врачи высшей категории с более чем 10-летним опытом и внимательным отношением к пациентам проводят осмотр, используя лучшее оборудование.',
        treatmentProcessSubText3: 'Только после тщательного осомтра пациента и изучения анализов, врач устанавливает диагноз и назначает соответвующее лечение',
    },
    contacts: {
        title: 'Наши контакты',
        phones: 'Телефоны',
        schedule: 'График работы',
        dayOff: 'выходной',
        address: 'Адрес',
    },
    aboutUs: {
        title: 'Немного о нас',
        text1: 'Медицинский центр Евролайф ведет свою деятельность согласно мировым стандартам качества медицинских услуг. В центре осуществляется диагностика, самые разные способы обследования и выявления широкого ряда заболеваний. При обследовании вы столкнетесь только с компетентными врачами, которые всегда рады оказать свои услуги.',
        text2: 'Здесь принимают врачи различных специальностей, в том числе терапевты, ортопеды и педиатры. Для вас доступны услуги процедурного кабинета и физиотерапии, а также ряд медицинских манипуляций',
        text3: 'Центр также предлагает ряд диагностических процедур: здесь вы можете сделать УЗИ, медосмотр, функциональную диагностику, лабораторную диагностику, психофизиологическую экспертизу',
    },
    footer: {
        title: 'КОНТАКТЫ',
        text: 'ВРЕМЯ РАБОТЫ',
    }
}
