import React from "react";
import './contacts-about-us.scss'
import {rusTexts} from "../../../texts/rusTexts";
import {ukrTexts} from "../../../texts/ukrTexts";
import Radium, {StyleRoot} from "radium";
import {fadeInUp} from "react-animations";

export const ContactsAboutUs = ({language}) => {
    const styles = {
        fadeInUp: {
            animation: 'x 1.3s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }
    return (
        <StyleRoot>
            <div className='contacts-about-us'>
                <div className='contacts-about-us__component-wrapper'>
                    <h2 style={styles.fadeInUp} className='contacts-about-us__title wow'>{language === 'rus' ? rusTexts.aboutUs.title : ukrTexts.aboutUs.title}</h2>
                    <div className='contacts-about-us__wrapper'>
                        <img style={styles.fadeInUp} src="/assets/contacts-about-us.png" alt="Евролайф"
                             className='contacts-about-us__img wow'/>
                        <p style={styles.fadeInUp} className='contacts-about-us__about-us-text wow'>
                            {language === 'rus' ? rusTexts.aboutUs.text1 : ukrTexts.aboutUs.text1}
                            <br/><br/>

                            {language === 'rus' ? rusTexts.aboutUs.text2 : ukrTexts.aboutUs.text2}
                            <br/><br/>

                            {language === 'rus' ? rusTexts.aboutUs.text3 : ukrTexts.aboutUs.text3}
                        </p>
                    </div>
                </div>
            </div>
        </StyleRoot>
    )
}