import React, {useEffect, useState} from "react";
import './header.scss'
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {mapLocation} from "../../variables";
import {rusTexts} from "../../texts/rusTexts";
import {ukrTexts} from "../../texts/ukrTexts";

export const Header = ({setModalWindow, language, setLanguage}) => {
    const [menu, setSMenu] = useState(false)
    let location = useLocation();

    useEffect(() => {
        menu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible'
    }, [menu])

    useEffect(() => {
        setSMenu(false)
        document.body.style.overflow = 'visible'
    }, [location])

    const handleModal = () => {
        setModalWindow(true)
        setSMenu(false)
        document.body.style.overflow = 'visible'
    }

    return (
        <header className="header">
            <div className='header__wrapper'>
                <div className="header__box">
                    <NavLink to='/'>
                        <img className="header__logo-link"
                             src="assets/logo.jpeg" alt="Евролайф"/>
                    </NavLink>
                    <a href={mapLocation} target="_blank"
                       rel="noopener noreferrer" className="header__location">
                        <img src="assets/location-icon.svg"
                             alt="Евролайф Днепр"/>
                        <p>{language === 'rus' ? rusTexts.location : ukrTexts.location}</p>
                    </a>
                </div>
                <nav className="header__box">
                    <NavLink activeClassName='header__link-active'
                             className="header__link"
                             to='/'
                             exact>{language === 'rus' ? rusTexts.header.main : ukrTexts.header.main}</NavLink>
                    <NavLink activeClassName='header__link-active'
                             className="header__link"
                             to='/направления'>{language === 'rus' ? rusTexts.header.services : ukrTexts.header.services}</NavLink>
                    <NavLink activeClassName='header__link-active'
                             className="header__link"
                             to='/врачи'>{language === 'rus' ? rusTexts.header.doctors : ukrTexts.header.doctors}</NavLink>
                    <NavLink activeClassName='header__link-active'
                             className="header__link"
                             to='/контакты'
                    >
                        {language === 'rus' ? rusTexts.header.contacts : ukrTexts.header.contacts}
                    </NavLink>
                    <button onClick={() => setModalWindow(true)}
                            className="header__link">{language === 'rus' ? rusTexts.header.signUp : ukrTexts.header.signUp}</button>
                </nav>
            </div>
            <div className='header__wrapper-small-permission'>
                <div className='header__wrapper-small-permission-menu'>
                    <NavLink to='/'>
                        <img className="header__logo-link"
                             src="assets/logo.jpeg" alt="Евролайф"/>
                    </NavLink>
                    <img onClick={() => setSMenu(!menu)}
                         className="header__open-menu-btn"
                         src="assets/header-small-permission.svg"
                         alt="Евролайф"/>
                </div>
                <div className='header__wrapper-small-permission-menu-plank'>
                    <div>
                        <img src="/assets/phone-icon.svg" alt="Евролайф"/>
                        <a className="header-plank__text"
                           href="tel:+380663452626">+38 066 345 26
                            26</a>
                        {/*<p>+38 066 345 26 26</p>*/}
                    </div>
                    <div>
                        <img src="/assets/phone-icon.svg" alt="Евролайф"/>
                        <a className="header-plank__text"
                           href="tel:+380963452626">+38 096 345 26
                            26</a>
                        {/*<p>+38 096 345 26 26</p>*/}
                    </div>
                </div>
                {
                    menu
                        ? <div
                            className="header__wrapper-small-permission-menu-links">

                            <NavLink activeClassName='header__link-active'
                                     className="header__link"
                                     to='/'>{language === 'rus' ? rusTexts.header.main : ukrTexts.header.main}</NavLink>
                            <NavLink activeClassName='header__link-active'
                                     className="header__link"
                                     to='/направления'>{language === 'rus' ? rusTexts.header.services : ukrTexts.header.services}</NavLink>
                            <NavLink activeClassName='header__link-active'
                                     className="header__link"
                                     to='/врачи'>{language === 'rus' ? rusTexts.header.doctors : ukrTexts.header.doctors}</NavLink>
                            <NavLink activeClassName='header__link-active'
                                     className="header__link"
                                     to='/контакты'>{language === 'rus' ? rusTexts.header.contacts : ukrTexts.header.contacts}</NavLink>
                            <button onClick={() => handleModal()}
                                    className="header__link">{language === 'rus' ? rusTexts.header.signUp : ukrTexts.header.signUp}</button>
                            <div
                                className='header-plank__language-wrapper header-plank__language-wrapper--small'>
                                <p
                                    className={language === 'rus' ? 'header-plank__language header-plank__language--active' : 'header-plank__language'}
                                    onClick={() => setLanguage('rus')}
                                >
                                    рус
                                </p>
                                |
                                <p
                                    className={language === 'ukr' ? 'header-plank__language header-plank__language--active' : 'header-plank__language'}
                                    onClick={() => setLanguage('ukr')}
                                >
                                    укр
                                </p>
                            </div>
                        </div>
                        : null
                }
            </div>
        </header>
    )
}