import React from "react";
import './contact-us-modal.scss'
import {IMaskInput} from "react-imask";
import {rusTexts} from "../../texts/rusTexts";
import {ukrTexts} from "../../texts/ukrTexts";

export const ContactUsModal = ({setModalWindow, name, service, phone, setName, setService, setPhone, sendMessage, language}) => {
    return (
        <div className='contact-us-modal'>
            <div className="contact-us-modal__wrapper">
                <img onClick={() => setModalWindow(false)} className='contact-us-modal__close-modal'
                     src="assets/close-btn.svg" alt="Евролайф"/>
                <p className='contact-us-modal__title'>{language === 'rus' ? rusTexts.signForm.subTitle : ukrTexts.signForm.subTitle}</p>
                <form className='contact-us-modal__form' action="" onSubmit={sendMessage}>
                    <label className='contact-us-modal__label' htmlFor="name">{language === 'rus' ? rusTexts.signForm.nameInput : ukrTexts.signForm.nameInput}</label>
                    <input placeholder={language === 'rus' ? rusTexts.signForm.nameLabel : ukrTexts.signForm.nameLabel} className='contact-us-modal__input' type="text"
                           id='name' value={name} onChange={event => setName(event.target.value)} required={true}/>
                    <label className='contact-us-modal__label' htmlFor="phone">{language === 'rus' ? rusTexts.signForm.phoneInput : ukrTexts.signForm.phoneInput}</label>
                    <IMaskInput
                        className='contact-us-modal__input' id='phone'
                        mask={'+{38} ({0}00) 000 00 00'}
                        value={phone}
                        radix="."
                        placeholder='+38 (0__) ___ __ __'
                        onAccept={(value, mask) => setPhone(value)}
                    />
                    <label className='contact-us-modal__label' htmlFor="service">{language === 'rus' ? rusTexts.signForm.serviceInput : ukrTexts.signForm.serviceInput}</label>
                    <input placeholder={language === 'rus' ? rusTexts.signForm.serviceLabel : ukrTexts.signForm.serviceLabel} className='contact-us-modal__input' type="text"
                           id="service" value={service} onChange={event => setService(event.target.value)}
                           required={true}/>
                    <button className='contact-us-modal__btn-submit'>{language === 'rus' ? rusTexts.signForm.signBtn : ukrTexts.signForm.signBtn}</button>
                </form>
                <p className='contact-us-modal__intermediate-text'>{language === 'rus' ? rusTexts.signForm.text : ukrTexts.signForm.text}</p>
                <p className='contact-us-modal__text'>{language === 'rus' ? rusTexts.signForm.text2 : ukrTexts.signForm.text2}</p>
                <p className='contact-us-modal__phone'>+38 066 345 26 26 <br/> +38 096 345 26 26</p>
            </div>
        </div>
    )
}