import React from "react";
import './main-services.scss'
import {listServices} from "../../../list-services";
import {NavLink} from "react-router-dom";
import {rusTexts} from "../../../texts/rusTexts";
import {ukrTexts} from "../../../texts/ukrTexts";
import Radium, {StyleRoot} from "radium";
import {fadeInUp} from "react-animations";

export const MainServices = ({stringToLink, language}) => {
    const styles = {
        fadeInUp: {
            animation: 'x 1.3s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }
    return (
        <div className='main-services'>
            <div className='main-services__wrapper'>
                <StyleRoot>
                    <h2 style={styles.fadeInUp} className='main-services__sign wow'>{language === 'rus' ? rusTexts.mainServices.title : ukrTexts.mainServices.title}</h2>
                    <p style={styles.fadeInUp} className='main-services__sub-title wow'>{language === 'rus' ? rusTexts.mainServices.subTitle : ukrTexts.mainServices.subTitle}</p>
                </StyleRoot>
                <div className='main-services__list'>
                    {listServices.map(el => (
                        // <NavLink to={`/${stringToLink(el.title.toLowerCase())}`} className='main-services__item' key={el.id}>
                        //     <img className='main-services__icon' src={el.icon} alt=""/>
                        //     <p className='main-services__name'>{el.title}</p>
                        // </NavLink>
                        <StyleRoot>
                            <div style={styles.fadeInUp}
                                 className='main-services__item wow'
                                 key={el.id}>
                                <img className='main-services__icon'
                                     src={el.icon} alt="Евролайф"/>
                                <p className='main-services__name'>{language === 'rus' ? el.title : el.ukrTitle}</p>
                            </div>
                        </StyleRoot>
                    ))}
                </div>
                <div className='main-services__btn-see-all-wrapper'>
                    <NavLink to='/направления'
                             className='main-services__btn-see-all'>{language === 'rus' ? rusTexts.mainServices.showMore : ukrTexts.mainServices.showMore}</NavLink>
                </div>
            </div>
        </div>
    )
}