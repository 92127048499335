export const ukrTexts = {
    header: {
        main: 'Головна',
        services: 'Напрями',
        doctors: 'Лікарі',
        contacts: 'Контакти',
        signUp: 'ЗАПИСАТИСЯ'
    },
    location: 'Дніпро, вулиця Панікахи, 27',
    mainHeader: {
        title: 'Індивідуальний підхід до кожного пацієнта\n',
        subTitle: 'Медичний центр Євролайф веде свою діяльність відповідно до світових стандартів якості медичних послуг. \n ' +
            'До кожного клієнта фахівці медцентру знаходять свій підхід.',
        signBtn: 'ЗАПИСАТИСЯ НА ПРИЙОМ',
        contentTitle1: 'Комфортна обстановка',
        contentTitle2: 'Досвідчені лікарі',
        contentTitle3: 'Сучасне обладнання',
        contentText1: 'Затишна клініка і привітний персонал допоможуть розслабитися і відчувати себе комфортно при кожному відвідуванні.',
        contentText2: 'Лікарі вищої категорії з увагою і повагою ставляться до кожного пацієнта.',
        contentText3: 'Точна діагностика і лікування завдяки якісному обладнанню.',
    },
    mainServices: {
        title: 'Напрями',
        subTitle: `У нашому центрі ви зможете отримати консультацію з важливих для вас
                     питань. Тут вас обстежують і на підставі цього призначать лікування. До кожного клієнта фахівці
                     медцентру Євролайф знаходять свій підхід.`,
        showMore: 'ПЕРЕГЛЯНУТИ ВСІ ПОСЛУГИ'
    },
    mainDoctors: {
        title: 'Наша команда',
        subTitle: `Кадровий потенціал медичного центру Євролайф представлений лікарями і медичними сестрами першої, вищої категорії, кандидати медичних наук, що дозволяє надавати кваліфіковану діагностичну та лікувальну допомогу на найвищому рівні.`,
        showMore: 'ПЕРЕГЛЯНУТИ ВСІХ ЛІКАРІВ'
    },
    mainReviews: {
        title: 'Відгуки',
    },
    signForm: {
        nameInput: 'Iм\'я',
        nameLabel: 'Введіть своє ім\'я',
        phoneInput: 'Номер телефону',
        serviceInput: 'Напрямок або лікар',
        serviceLabel: 'Введіть напрямок, послугу або прізвище лікаря',
        signBtn: 'ЗАПИСАТИСЯ',
        title: 'Записатися на прийом',
        subTitle: 'Залиште свої дані і ми вам передзвонимо',
        text: 'або',
        text2: 'зателефонуйте нам самі',
        success: 'Ваш запит відправлено!',
        success2: 'Ми зв\'яжемося з вами найближчим часом.',
        returnBtn: 'ПОВЕРНУТИСЯ НА САЙТ',
    },
    services: {
        title: 'Напрями',
        subTitle: 'Назва напряму',
        treatmentProcess: 'Як проходить процес лікування',
        treatmentProcessSubTitle1: 'Збір інформації',
        treatmentProcessSubTitle2: 'Огляд',
        treatmentProcessSubTitle3: 'Встановлення діагнозу і призначення',
        treatmentProcessSubText1: 'Наші фахівці підходять до збору інформації з великою відповідальністю',
        treatmentProcessSubText2: 'Лікарі вищої категорії з більш ніж 10-річним досвідом і уважним ставленням до пацієнтів проводять огляд, використовуючи найкраще обладнання.',
        treatmentProcessSubText3: 'Тільки після ретельного огляду пацієнта і вивчення аналізів, лікар встановлює діагноз і призначає соответвуют лікування',
    },
    contacts: {
        title: 'Наші контакти',
        phones: 'телефони',
        schedule: 'Графік роботи',
        dayOff: 'вихідний',
        address: 'Адреса',
    },
    aboutUs: {
        title: 'Трохи про нас',
        text1: 'Медичний центр Євролайф-Центр веде свою діяльність відповідно до світових стандартів якості медичних послуг. У центрі здійснюється діагностика, найрізноманітніші способи обстеження і виявлення широкого ряду захворювань. При обстеженні ви зіткнетеся тільки з компетентними лікарями, які завжди раді надати свої послуги.',
        text2: 'Тут приймають лікарі різних спеціальностей, в тому числі терапевти, ортопеди і педіатри. Для вас доступні послуги процедурного кабінету і фізіотерапії, а також ряд медичних маніпуляцій',
        text3: 'Центр також пропонує ряд діагностичних процедур: тут ви можете зробити УЗД, медогляд, функціональну діагностику, лабораторну діагностику, психофізіологічну експертизу',
    },
    footer: {
        title: 'КОНТАКТИ',
        text: 'ЧАС РОБОТИ',
    }
}
