import React from "react";
import './contact-us-component.scss'
import {IMaskInput} from "react-imask";
import {rusTexts} from "../../texts/rusTexts";
import {ukrTexts} from "../../texts/ukrTexts";
import Radium, {StyleRoot} from "radium";
import {fadeInUp} from "react-animations";

export const ContactUsComponent = ({
                                       name,
                                       service,
                                       phone,
                                       setName,
                                       setService,
                                       setPhone,
                                       sendMessage,
                                       language
                                   }) => {
    const styles = {
        fadeInUp: {
            animation: 'x 1.3s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }

    return (
        <div className='contact-us-component'>
            <div className='contact-us-component__wrapper'>
                <div
                    className='contact-us-component__content'>
                    <StyleRoot>
                        <h5 style={styles.fadeInUp}
                            className='contact-us-component__title wow'>{language === 'rus' ? rusTexts.signForm.title : ukrTexts.signForm.title}</h5>
                        <p style={styles.fadeInUp}
                           className='contact-us-component__sub-title wow'>{language === 'rus' ? rusTexts.signForm.subTitle : ukrTexts.signForm.subTitle}</p>
                    </StyleRoot>
                </div>
                <StyleRoot>
                    <form style={styles.fadeInUp} className='contact-us-component__form wow'
                          onSubmit={sendMessage}>
                        <div className='contact-us-component__form-wrapper'>
                            <label
                                className='contact-us-component__form-label'
                                htmlFor="name">{language === 'rus' ? rusTexts.signForm.nameInput : ukrTexts.signForm.nameInput}</label>
                            <input
                                className='contact-us-component__form-input'
                                id='name' type="text"
                                value={name}
                                onChange={event => setName(event.target.value)}
                                placeholder={language === 'rus' ? rusTexts.signForm.nameLabel : ukrTexts.signForm.nameLabel}
                                required={true}/>
                        </div>
                        <div className='contact-us-component__form-wrapper'>
                            <label className='contact-us-component__form-label'
                                   htmlFor="phone">{language === 'rus' ? rusTexts.signForm.phoneInput : ukrTexts.signForm.phoneInput}</label>
                            <IMaskInput
                                className='contact-us-component__form-input'
                                id='phone'
                                mask={'+{38} ({0}00) 000 00 00'}
                                value={phone}
                                radix="."
                                placeholder='+38 (0__) ___ __ __'
                                onAccept={(value, mask) => setPhone(value)}
                                required={true}
                            />
                        </div>
                        <div className='contact-us-component__form-wrapper'>
                            <label className='contact-us-component__form-label'
                                   htmlFor="name">{language === 'rus' ? rusTexts.signForm.serviceInput : ukrTexts.signForm.serviceInput}</label>
                            <input className='contact-us-component__form-input'
                                   id='name' type="text"
                                   value={service}
                                   onChange={event => setService(event.target.value)}
                                   placeholder={language === 'rus' ? rusTexts.signForm.serviceLabel : ukrTexts.signForm.serviceLabel}
                                   required={true}/>
                        </div>
                        <button className='contact-us-component__form-submit'
                                type='submit'>{language === 'rus' ? rusTexts.signForm.signBtn : ukrTexts.signForm.signBtn}</button>
                    </form>
                </StyleRoot>
            </div>
        </div>
    )
}