export const doctors = [
    {
        id: 'doc1',
        name: 'Донская Юлия Витальевна',
        ukrName: 'Донська Юлія Віталіївна',
        specialization: 'Гинеколог',
        ukrSpecialization: 'Гінеколог',
        avatar: 'assets/doctors/Donska2.jpg',
        experience: '15 лет',
        ukrExperience: '15 років',
        category: 'Кандидат Медицинских Наук',
        ukrCategory: 'Кандидат Медичних Наук',
        secondCategory: 'Врач Высшей Категория',
        ukrSecondCategory: 'Врач Вищої Категорії',
    },
    {
        id: 'doc11',
        name: 'Полтавец Антонина Павловна ',
        ukrName: 'Полтавець Антоніна Павлівна',
        specialization: 'Отоларинголог',
        ukrSpecialization: 'Отоларинголог',
        avatar: 'assets/doctors/Poltavets2.jpg',
        experience: '25 лет',
        ukrExperience: '25 років',
        category: 'Высшая Категория',
        ukrCategory: 'Вища Категорія',
    },
    {
        id: 'doc12',
        name: 'Савченко Константин Юрьевич',
        ukrName: 'Савченко Костянтин Юрійович',
        specialization: 'Невропатолог',
        ukrSpecialization: 'Невропатолог',
        avatar: 'assets/doctors/Savchenko.jpg',
        experience: '15 лет',
        ukrExperience: '15 років',
        category: 'Первая категория',
        ukrCategory: 'Перша категорія',
    },
    {
        id: 'doc2',
        name: 'Корягин Вадим Михайлович',
        ukrName: 'Корягін Вадим Михайлович',
        specialization: 'Уролог',
        ukrSpecialization: 'Уролог',
        avatar: 'assets/doctors/male2.svg',
        experience: '29 лет',
        ukrExperience: '29 років',
        category: 'Высшая Категория',
        ukrCategory: 'Вища Категорія',
    },
    {
        id: 'doc4',
        name: 'Люлько Андрей Алексеевич',
        ukrName: 'Люлько Андрій Олексійович',
        specialization: 'УЗИ',
        ukrSpecialization: 'УЗД',
        avatar: 'assets/doctors/Lulko.jpg',
        experience: '15 лет',
        ukrExperience: '15 років',
        category: 'Высшая Категория',
        ukrCategory: 'Вища Категорія',
    },
    {
        id: 'doc5',
        name: 'Ильченко Игорь Григорьевич',
        ukrName: 'Ільченко Ігор Григорович',
        specialization: 'УЗИ',
        ukrSpecialization: 'УЗД',
        avatar: 'assets/doctors/male2.svg',
        experience: '15 лет',
        ukrExperience: '15 років',
        category: 'Высшая Категория',
        ukrCategory: 'Вища Категорія',
    },
    {
        id: 'doc3',
        name: 'Плещенко Марина Леонидовна',
        ukrName: 'Плещенко Марина Леонідівна',
        specialization: 'Гастроэнтеролог',
        ukrSpecialization: 'Гастроентеролог',
        avatar: 'assets/doctors/female2.svg',
        experience: '32 года',
        ukrExperience: '32 роки',
        category: 'Высшая Категория',
        ukrCategory: 'Вища Категорія',
    },
    {
        id: 'doc9',
        name: 'Салоид Инна Николаевна',
        ukrName: 'Салоід Інна Миколаївна',
        specialization: 'Дерматовенеролог',
        ukrSpecialization: 'Дерматовенеролог',
        avatar: 'assets/doctors/female2.svg',
        experience: '24 года',
        ukrExperience: '24 роки',
        category: 'Высшая Категория',
        ukrCategory: 'Вища Категорія',
    },
    {
        id: 'doc10',
        name: 'Загурский Юрий Борисович',
        ukrName: 'Загурський Юрій Борисович',
        specialization: 'Дерматовенеролог',
        ukrSpecialization: 'Дерматовенеролог',
        avatar: 'assets/doctors/male2.svg',
        experience: '20 лет',
        ukrExperience: '20 років',
        category: 'Высшая Категория',
        ukrCategory: 'Вища Категорія',
    },
]
