import React, {useEffect, useState} from "react";
import './services.scss'
import {NavLink} from "react-router-dom";
import {listServices} from "../../list-services";
import {TreatmentProcess} from "./treatmentProcess/treatmentProcess";
import {rusTexts} from "../../texts/rusTexts";
import {ukrTexts} from "../../texts/ukrTexts";
import Radium, {StyleRoot} from "radium";
import {fadeInUp} from "react-animations";

export const Services = ({stringToLink, language}) => {
    let [listServicesWithToggle, setListServicesWithToggle] = useState([])

    const handleToggle = (event, id) => {
        event.preventDefault()
        event.stopPropagation()

        setListServicesWithToggle(listServicesWithToggle.map(service => {
            if (service.id === id) {
                return {
                    ...service,
                    toggle: !service.toggle
                }
            } else {
                return {
                    ...service,
                    toggle: service.toggle
                }
            }
        }))
    }

    useEffect(() => {
        document.title = 'Направления | Евролайф'
        setListServicesWithToggle(listServices.map(service => {
            return {
                ...service,
                toggle: false
            }
        }))

    }, [])

    const styles = {
        fadeInUp: {
            animation: 'x 1.3s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }

    return (
        <div className='services'>
            <div className='services__wrapper'>
                <div className='links'>
                    <div className='links__wrapper'>
                        <NavLink className="links__link-text"
                                 to='/'>{language === 'rus' ? rusTexts.header.main : ukrTexts.header.main}</NavLink>
                        <p className='links__link-text'>
                            <img src="/assets/arrow-left.svg" alt="Евролайф"/>
                        </p>
                        <p className='links__link-text'>{language === 'rus' ? rusTexts.header.services : ukrTexts.header.services}</p>
                    </div>
                </div>
                <h1 className='services__title'>{language === 'rus' ? rusTexts.services.title : ukrTexts.services.title}</h1>
                <div className='services__services-list-wrapper'>
                    <h2 className='services__services-title'>{language === 'rus' ? rusTexts.services.subTitle : ukrTexts.services.subTitle}</h2>
                    <div className='services__services-list'>
                        {listServicesWithToggle.map(service => (
                            // <NavLink key={service.id}
                            //          className={service.toggle ? 'services__services-list-item--active' : 'services__services-list-item'}
                            //          to={`/${stringToLink(service.title.toLowerCase())}`}
                            // >
                            <StyleRoot>
                                <div key={service.id}
                                     style={styles.fadeInUp}
                                    // className={service.toggle ? 'services__services-list-item--active' : 'services__services-list-item'}
                                     className='services__services-list-item wow'
                                >
                                    <div
                                        className='services__services-list-item-wrapper'>
                                        <img
                                            onClick={(event) => handleToggle(event, service.id)}
                                            // className={service.toggle ? 'services__service-arrow--active' : 'services__service-arrow'}
                                            className='services__service-arrow'
                                            src="assets/services-arrow.svg"
                                            alt="Евролайф"/>
                                        <p onClick={(event) => handleToggle(event, service.id)}
                                           className='services__service-title'>{language === 'rus' ? service.title : service.ukrTitle}</p>
                                    </div>
                                    {/*{service.toggle*/}
                                    {/*    ? <div className='services__extended-service'>*/}
                                    {/*        {service.toggle*/}
                                    {/*            ? service.extendedService.map(el => <p*/}
                                    {/*                key={el}*/}
                                    {/*                className='services__extended-service-title'*/}
                                    {/*            >*/}
                                    {/*                {el}*/}
                                    {/*            </p>)*/}
                                    {/*            : null*/}
                                    {/*        }*/}
                                    {/*    </div>*/}
                                    {/*    : null*/}
                                    {/*}*/}
                                </div>
                            </StyleRoot>
                        ))}
                    </div>
                </div>
                <TreatmentProcess language={language}/>
            </div>
        </div>
    )
}