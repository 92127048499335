import React, {useEffect} from "react";
import {ContactsAboutUs} from "./contacts-about-us/contacts-about-us";
import {ContactsOurContacts} from "./contacts-our-contacts/contacts-our-contacts";
import './contacts.scss'

export const Contacts = ({language}) => {
    useEffect(() => {
        document.title = 'Контакты | Евролайф'
    }, [])

    return (
        <div>
            <ContactsOurContacts language={language}/>
            <ContactsAboutUs language={language}/>
        </div>
    )
}