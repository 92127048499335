export const reviews = [
    {
        id: 1,
        name: 'Илья',
        date: '10.12.2019',
        text: 'Клиникой доволен. Был на УЗИ и у других специалистов. Ничего плохого сказать не могу, внимательные, приятные медсестры. Профессиональные доктора. Выписали правильное лечение, которое уже помогло. Большое всем спасибо.',
        stars: [1, 2, 3, 4, 5]
    },
    {
        id: 2,
        name: 'Маша',
        date: '01.09.2020',
        text: 'Была на днях в центре , приводила свою дочку к дерматологу , посмотрела , назначила лечения , сейчас лечимся ... :) Доктор внимательный , вроде все в клинике не дорого , пока нравится.',
        stars: [1, 2, 3, 4, 5]
    },
    {
        id: 3,
        name: 'Татьяна',
        date: '25.02.2020',
        text: 'Хорошая клиника, хороший персонал, хорошие специалисты . Отдельно отмечу гинеколога, у которого наблюдаюсь, которого готова рекомендовать. Спасибо',
        stars: [1, 2, 3, 4, 5]
    },
]