import React from "react";
import './main-header.scss'
import {rusTexts} from "../../../texts/rusTexts";
import {ukrTexts} from "../../../texts/ukrTexts";
import {fadeInUp} from 'react-animations'
import Radium, {StyleRoot} from 'radium';

export const MainHeader = ({setModalWindow, language}) => {
    const styles = {
        fadeInUp: {
            animation: 'x 1.2s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }

    return (
        <section className='main-header'>
            <div className='main-header__section'>
                <div className="main-header__background-small-permission"/>
                <StyleRoot>
                    <h1 style={styles.fadeInUp} className='main-header__title wow'>{language === 'rus' ? rusTexts.mainHeader.title : ukrTexts.mainHeader.title}</h1>
                </StyleRoot>
                <StyleRoot>
                    <p style={styles.fadeInUp} className='main-header__sub-title wow'>{language === 'rus' ? rusTexts.mainHeader.subTitle : ukrTexts.mainHeader.subTitle}</p>
                </StyleRoot>
                <button onClick={() => setModalWindow(true)}
                        className='main-header__btn'>{language === 'rus' ? rusTexts.mainHeader.signBtn : ukrTexts.mainHeader.signBtn}</button>
            </div>

            <div className="main-header__container-wrapper">
                <div className='main-header__container'>
                    <div className='main-header__box'>
                        <StyleRoot>
                            <h2 style={styles.fadeInUp}
                                className='main-header__box-title wow'>{language === 'rus' ? rusTexts.mainHeader.contentTitle1 : ukrTexts.mainHeader.contentTitle1}</h2>
                            <p style={styles.fadeInUp}
                               className='main-header__box-text wow'>{language === 'rus' ? rusTexts.mainHeader.contentText1 : ukrTexts.mainHeader.contentText1}</p>
                        </StyleRoot>
                    </div>
                    <div className='main-header__box'>
                        <StyleRoot>
                            <h2 style={styles.fadeInUp}
                                className='main-header__box-title'>{language === 'rus' ? rusTexts.mainHeader.contentTitle2 : ukrTexts.mainHeader.contentTitle2}</h2>
                            <p style={styles.fadeInUp}
                               className='main-header__box-text'>{language === 'rus' ? rusTexts.mainHeader.contentText2 : ukrTexts.mainHeader.contentText2}</p>
                        </StyleRoot>
                    </div>
                    <div className='main-header__box'>
                        <StyleRoot>
                            <h2 style={styles.fadeInUp}
                                className='main-header__box-title'>{language === 'rus' ? rusTexts.mainHeader.contentTitle3 : ukrTexts.mainHeader.contentTitle3}</h2>
                            <p style={styles.fadeInUp}
                               className='main-header__box-text'>{language === 'rus' ? rusTexts.mainHeader.contentText3 : ukrTexts.mainHeader.contentText3}</p>
                        </StyleRoot>
                    </div>
                </div>
            </div>
        </section>
    )
}