import React, {useEffect, useState} from "react";
import Carousel, {Dots} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './main-reviews.scss'
import {MainReviewItem} from "./main-reviews-item";
import {reviews} from '../../../reviews'
import {rusTexts} from "../../../texts/rusTexts";
import {ukrTexts} from "../../../texts/ukrTexts";
import Radium, {StyleRoot} from "radium";
import {fadeInUp} from "react-animations";


export const MainReviews = ({language}) => {
    const [page, setPage] = useState(0)
    const [screenWidth, setScreenWidth] = useState(0)
    const [screenWidthLessTen765, setScreenWidthLessTen765] = useState(false)

    const updateWindowDimensions = () => setScreenWidth(window.innerWidth)

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        if (screenWidth < 765) {
            setScreenWidthLessTen765(true)
        } else {
            setScreenWidthLessTen765(false)
        }
    }, [screenWidth])

    const styles = {
        fadeInUp: {
            animation: 'x 1.3s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }

    return (
        <div className='main-reviews'>
            <StyleRoot>
                <h2 style={styles.fadeInUp}
                    className='main-reviews__sign wow'>{language === 'rus' ? rusTexts.mainReviews.title : ukrTexts.mainReviews.title}</h2>
            </StyleRoot>
            <Carousel
                arrowLeft={<img
                    className='main-reviews__arrow main-reviews__arrow-left'
                    src='assets/carousel-arrow.svg'
                    alt="Евролайф"/>}
                arrowRight={<img className='main-reviews__arrow'
                                 src='assets/carousel-arrow.svg'
                                 alt="Евролайф"/>}
                addArrowClickHandler
                slidesPerPage={screenWidthLessTen765 ? 1 : 3}
                slidesPerScroll={1}
                animationSpeed={1000}
                autoPlay={screenWidthLessTen765 ? 8000 : null}
                itemWidth={screenWidthLessTen765 ? 320 : 380}
                offset={screenWidthLessTen765 ? 0 : 40}
                stopAutoPlayOnHover
                infinite
                onChange={page => setPage(page)}
                value={page}
            >
                {reviews.map(review => (
                    <MainReviewItem key={review.id} review={review}/>
                ))}
            </Carousel>
            <Dots value={page} onChange={page => setPage(page)}
                  number={screenWidthLessTen765 ? 3 : 1}/>
        </div>
    )
}