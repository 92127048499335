import React, {useEffect} from "react";
import {MainHeader} from "./main-header/main-header";
import {MainServices} from "./main-services/main-services";
import './main.scss'
import {MainReviews} from "./main-reviews/main-reviews";
import {MainDoctors} from "./main-doctors/main-doctors";

export const Main = ({setModalWindow, stringToLink, language}) => {
    useEffect(() => {
        document.title = 'Евролайф'
    }, [])

    return (
        <main className='main'>
            <MainHeader setModalWindow={setModalWindow} language={language}/>
            <MainServices stringToLink={stringToLink} language={language}/>
            <MainDoctors language={language}/>
            <MainReviews language={language}/>
        </main>
    )
}