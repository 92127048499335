import React from "react";
import './contacts-our-contacts.scss'
import {NavLink} from "react-router-dom";
import {mapLocation} from "../../../variables";
import {rusTexts} from "../../../texts/rusTexts";
import {ukrTexts} from "../../../texts/ukrTexts";
import Radium, {StyleRoot} from "radium";
import {fadeInUp} from "react-animations";

export const ContactsOurContacts = ({language}) => {
    const styles = {
        fadeInUp: {
            animation: 'x 1.3s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }
    return (
        <StyleRoot>
            <div className='contacts-our-contacts'>
                <div className='contacts-our-contacts__component-wrapper'>
                    <div className='links'>
                        <div className='links__wrapper'>
                            <NavLink className="links__link-text"
                                     to='/'>{language === 'rus' ? rusTexts.header.main : ukrTexts.header.main}</NavLink>
                            <p className='links__link-text'>
                                <img src="/assets/arrow-left.svg"
                                     alt="Евролайф"/>
                            </p>
                            <p className='links__link-text'>{language === 'rus' ? rusTexts.header.contacts : ukrTexts.header.contacts}</p>
                        </div>
                    </div>
                    <h1 style={styles.fadeInUp} className='contacts-our-contacts__sign wow'>{language === 'rus' ? rusTexts.contacts.title : ukrTexts.contacts.title}</h1>
                    <div className='contacts-our-contacts__cards'>
                        <div style={styles.fadeInUp} className='contacts-our-contacts__box wow'>
                            <img src="/assets/phone-icon-contacts.svg"
                                 alt="Евролайф"/>
                            <div className='contacts-our-contacts__wrapper'>
                                <h3 className='contacts-our-contacts__title'>{language === 'rus' ? rusTexts.contacts.phones : ukrTexts.contacts.phones}</h3>
                                <p className='contacts-our-contacts__text'>+38
                                    066 345 26 26</p>
                                <p className='contacts-our-contacts__text'>+38
                                    096 345 26 26</p>
                            </div>
                        </div>
                        <div style={styles.fadeInUp} className='contacts-our-contacts__box wow'>
                            <img src="/assets/schedule-icon.svg"
                                 alt="Евролайф"/>
                            <div className='contacts-our-contacts__wrapper'>
                                <h3 className='contacts-our-contacts__title'>{language === 'rus' ? rusTexts.contacts.schedule : ukrTexts.contacts.schedule}</h3>
                                <p className='contacts-our-contacts__text'>Пн-Пт:
                                    08:00-19:00</p>
                                <p className='contacts-our-contacts__text'>Сб:
                                    09:00-15:00</p>
                                <p className='contacts-our-contacts__text'>
                                    {language === 'rus' ? `Вс: ${rusTexts.contacts.dayOff}` : `Нд: ${ukrTexts.contacts.dayOff}`}</p>
                            </div>
                        </div>
                        <div style={styles.fadeInUp} className='contacts-our-contacts__box wow'>
                            <img src="/assets/adress-icon.svg"
                                 alt="Евролайф Днепр"/>
                            <div className='contacts-our-contacts__wrapper'>
                                <h3 className='contacts-our-contacts__title'>{language === 'rus' ? rusTexts.contacts.address : ukrTexts.contacts.address}</h3>
                                <a href={mapLocation} target="_blank"
                                   rel="noopener noreferrer"
                                   className='contacts-our-contacts__text'>{language === 'rus' ? rusTexts.location : ukrTexts.location}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyleRoot>
    )
}