import React, {useEffect, useState} from 'react';
import './App.css';
import './resetcss/reset.css'
import {Route, Switch, useLocation} from "react-router-dom";
import {Main} from "./components/main/main";
import {Doctors} from "./components/doctors/doctors";
import {Services} from "./components/services/services";
import {Header} from "./components/header/header";
import {Contacts} from "./components/contacts/contacts";
import {Footer} from "./components/footer/footer";
import {ContactUsComponent} from "./components/contact-us/contact-us-component";
import {ContactUsModal} from "./components/contact-us/contact-us-modal";
import {ServiceItem} from "./components/service-item/service-item";
import {SuccessMessage} from "./components/contact-us/success-message";
import {useCookies} from "react-cookie";
import axios from "axios";

function App() {
    const [language, setLanguage] = useState('rus')
    const [modalWindow, setModalWindow] = useState(false)
    const [modalWindowSuccess, setModalWindowSuccess] = useState(false)
    const [name, setName] = useState('')
    const [service, setService] = useState('')
    const [phone, setPhone] = useState('')
    const [cookies, setCookie] = useCookies(['language']);

    axios.defaults.baseURL = 'https://us-central1-eurolife-dp-ua.cloudfunctions.net/api'

    const {pathname} = useLocation();

    useEffect(() => {
        setLanguage(cookies.language)
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname]);

    useEffect(() => {
        setCookie('language', language)
    }, [language, setCookie]);

    const sendMessage = async (event) => {
        event.preventDefault()

        const newRecord = {
            name: name,
            service: service,
            phone: phone,
        }

        await axios.post('/records', newRecord)

        setName('')
        setService('')
        setPhone('')
        setModalWindowSuccess(true)
        setModalWindow(false)
    }

    const stringToLink = string => {
        return encodeURIComponent(string)
            .replace(/%20/g, "_")
    }

    return (
        <div className="app">
            <div>
                <div className='header-plank__wrapper'>
                    <div className='header-plank'>
                        <div className='header-plank__language-wrapper'>
                            <p
                                className={language === 'rus' ? 'header-plank__language header-plank__language--active' : 'header-plank__language'}
                                onClick={() => setLanguage('rus')}
                            >
                                рус
                            </p>
                            |
                            <p
                                className={language === 'ukr' ? 'header-plank__language header-plank__language--active' : 'header-plank__language'}
                                onClick={() => setLanguage('ukr')}
                            >
                                укр
                            </p>
                        </div>
                        <div>
                            <p className="header-plank__text">Пн-Пт:
                                08:00-19:00; Сб: 09:00-15:00</p>
                            <div>
                                <img src="/assets/phone-icon.svg"
                                     alt="Евролайф"/>
                                <a className="header-plank__text"
                                   href="tel:+380663452626">+38 066 345 26
                                    26</a>
                            </div>
                            <div>
                                <img src="/assets/phone-icon.svg"
                                     alt="Евролайф"/>
                                <a className="header-plank__text"
                                   href="tel:+380963452626">+38 096 345 26
                                    26</a>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    modalWindow
                        ? <ContactUsModal
                            setModalWindow={setModalWindow}
                            name={name}
                            service={service}
                            phone={phone}
                            setName={setName}
                            setService={setService}
                            setPhone={setPhone}
                            sendMessage={sendMessage}
                            language={language}
                        />
                        : null
                }
                {
                    modalWindowSuccess
                        ? <SuccessMessage
                            setModalWindowSuccess={setModalWindowSuccess}
                            language={language}
                        />
                        : null
                }
                <Header setModalWindow={setModalWindow} language={language}
                        setLanguage={setLanguage}/>
                <Switch>
                    <Route path='/' exact
                           render={() => <Main stringToLink={stringToLink}
                                               setModalWindow={setModalWindow}
                                               language={language}/>}/>
                    <Route path='/врачи' exact
                           render={() => <Doctors language={language}/>}/>
                    <Route path='/направления' exact
                           render={() => <Services language={language}
                                                   stringToLink={stringToLink}/>}/>
                    <Route path='/контакты' exact
                           render={() => <Contacts language={language}/>}/>
                    <Route path='/:service' exact component={ServiceItem}/>
                </Switch>
                <ContactUsComponent
                    name={name}
                    service={service}
                    phone={phone}
                    setName={setName}
                    setService={setService}
                    setPhone={setPhone}
                    sendMessage={sendMessage}
                    modalWindowSuccess={modalWindowSuccess}
                    language={language}
                />
                <Footer language={language}/>
            </div>
        </div>
    );
}

export default App;
