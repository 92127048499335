import React, {useEffect, useState} from "react";
import {doctors} from '../../../doctors'
import './main-doctors.scss'
import {NavLink} from "react-router-dom";
import {rusTexts} from "../../../texts/rusTexts";
import {ukrTexts} from "../../../texts/ukrTexts";
import Radium, {StyleRoot} from "radium";
import {fadeInUp} from "react-animations";

export const MainDoctors = ({language}) => {
    const [threeDoctors, setThreeDoctors] = useState([])

    useEffect(() => {
        setThreeDoctors([...doctors].splice(0, 3))
    }, [])

    const styles = {
        fadeInUp: {
            animation: 'x 1.3s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }

    return (
        <div className="main-doctors">
            <div className="main-doctors__wrapper">
                <StyleRoot>
                    <h2 style={styles.fadeInUp}
                        className="main-doctors__title wow">{language === 'rus' ? rusTexts.mainDoctors.title : ukrTexts.mainDoctors.title}</h2>
                    <p style={styles.fadeInUp}
                       className="main-doctors__subtitle wow">{language === 'rus' ? rusTexts.mainDoctors.subTitle : ukrTexts.mainDoctors.subTitle}</p>
                </StyleRoot>
                <div className="main-doctors__list">
                    {threeDoctors.map(doctor => (
                        <StyleRoot>
                            <div style={styles.fadeInUp} key={doctor.id}
                                 className="main-doctors__list-item wow">
                                <div
                                    className="main-doctors__doctor-avatar-wrapper">
                                    <img className="main-doctors__doctor-avatar"
                                         src={doctor.avatar} alt="Евролайф"/>
                                </div>
                                <h3 className="main-doctors__doctor-name">{language === 'rus' ? doctor.name : doctor.ukrName}</h3>
                                <p className="main-doctors__doctor-specialization">{language === 'rus' ? doctor.specialization : doctor.ukrSpecialization}</p>
                            </div>
                        </StyleRoot>
                    ))}
                </div>
                <div className='main-doctors__btn-see-all-wrapper'>
                    <NavLink to='/врачи'
                             className='main-doctors__btn-see-all'>{language === 'rus' ? rusTexts.mainDoctors.showMore : ukrTexts.mainDoctors.showMore}</NavLink>
                </div>
            </div>
        </div>
    )
}