import React from "react";

export const MainReviewItem = ({review}) => {

    return (
        <div className='main-reviews__card'>
            <h2 className='main-reviews__card-name'>{review.name}</h2>
            <h3 className='main-reviews__card-date'>{review.date}</h3>
            <p className='main-reviews__card-text'>{review.text}</p>
            <div className='main-reviews__card-stars-wrapper'>
                {review.stars.map(star => (
                   <React.Fragment key={star}>
                       <img src="assets/carousel-star.svg" alt="Евролайф"/>
                   </React.Fragment>
                ))}
            </div>
        </div>
    )
}
