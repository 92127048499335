import React from "react";
import './treatmentProcess.scss'
import {rusTexts} from "../../../texts/rusTexts";
import {ukrTexts} from "../../../texts/ukrTexts";
import Radium, {StyleRoot} from "radium";
import {fadeInUp} from "react-animations";

export const TreatmentProcess = ({language}) => {
    const styles = {
        fadeInUp: {
            animation: 'x 1.3s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }
    return (
        <StyleRoot>
            <div className="treatment-process">
                <div className='treatment-process__wrapper'>
                    <h2 style={styles.fadeInUp} className="treatment-process__title wow">{language === 'rus' ? rusTexts.services.treatmentProcess : ukrTexts.services.treatmentProcess}</h2>
                    <div className="treatment-process__container">
                        <div className="treatment-process__box">
                            <p style={styles.fadeInUp} className="treatment-process__number wow">1</p>
                            <div className="treatment-process__content">
                                <h3 style={styles.fadeInUp} className="treatment-process__sub-title wow">{language === 'rus' ? rusTexts.services.treatmentProcessSubTitle1 : ukrTexts.services.treatmentProcessSubTitle1}</h3>
                                <p style={styles.fadeInUp} className="treatment-process__text wow">{language === 'rus' ? rusTexts.services.treatmentProcessSubText1 : ukrTexts.services.treatmentProcessSubText1}</p>
                            </div>
                        </div>
                        <div className="treatment-process__box">
                            <p style={styles.fadeInUp} className="treatment-process__number wow">2</p>
                            <div className="treatment-process__content">
                                <h3 style={styles.fadeInUp} className="treatment-process__sub-title wow">{language === 'rus' ? rusTexts.services.treatmentProcessSubTitle2 : ukrTexts.services.treatmentProcessSubTitle2}</h3>
                                <p style={styles.fadeInUp} className="treatment-process__text wow">{language === 'rus' ? rusTexts.services.treatmentProcessSubText2 : ukrTexts.services.treatmentProcessSubText2}</p>
                            </div>
                        </div>
                        <div className="treatment-process__box">
                            <p style={styles.fadeInUp} className="treatment-process__number wow">3</p>
                            <div className="treatment-process__content">
                                <h3 style={styles.fadeInUp} className="treatment-process__sub-title wow">{language === 'rus' ? rusTexts.services.treatmentProcessSubTitle3 : ukrTexts.services.treatmentProcessSubTitle3}</h3>
                                <p style={styles.fadeInUp} className="treatment-process__text wow">{language === 'rus' ? rusTexts.services.treatmentProcessSubText3 : ukrTexts.services.treatmentProcessSubText3}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyleRoot>
    )
}