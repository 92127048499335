import React, {useEffect, useState} from "react";
import './doctors.scss'
import {NavLink} from "react-router-dom";
import {doctors} from "../../doctors";
import {rusTexts} from "../../texts/rusTexts";
import {ukrTexts} from "../../texts/ukrTexts";
import Radium, {StyleRoot} from "radium";
import {fadeInUp} from "react-animations";

export const Doctors = ({language}) => {
    const [filteredDoctors, setFilteredDoctors] = useState([])
    const [filteredDoctorsBySpecialization, setFilteredDoctorsBySpecialization] = useState(doctors)
    const [selectedSpecialization, setSelectedSpecialization] = useState('Все врачи')

    const handleFilter = specialization => {
        if (specialization === 'Все врачи') {
            setFilteredDoctorsBySpecialization(doctors)
            setSelectedSpecialization('Все врачи')
        } else {
            setSelectedSpecialization(specialization)
            setFilteredDoctorsBySpecialization(doctors.filter(doctor => {
                return doctor.specialization === specialization
            }))
        }
    }

    useEffect(() => {
        document.title = 'Врачи | Евролайф'

        doctors.forEach(doctor => {
            const isDoctorExist = filteredDoctors.find(filteredDoctor => {
                return doctor.specialization === filteredDoctor.specialization
            })

            if (!isDoctorExist) setFilteredDoctors([...filteredDoctors, doctor])
        })

    }, [filteredDoctors])

    const styles = {
        fadeInUp: {
            animation: 'x 1.3s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
    }

    return (
        <StyleRoot>
            <div className="doctors">
                <div className="doctors__wrapper">
                    <div className='links'>
                        <div className='links__wrapper'>
                            <NavLink  className="links__link-text"
                                     to='/'>{language === 'rus' ? rusTexts.header.main : ukrTexts.header.main}</NavLink>
                            <p className='links__link-text'>
                                <img src="/assets/arrow-left.svg"
                                     alt="Евролайф"/>
                            </p>
                            <p className='links__link-text'>{language === 'rus' ? rusTexts.header.doctors : ukrTexts.header.doctors}</p>
                        </div>
                    </div>
                    <h1 style={styles.fadeInUp} className="doctors__title wow">{language === 'rus' ? rusTexts.header.doctors : ukrTexts.header.doctors}</h1>
                    <div className="doctors__selectors">
                        <p onClick={() => handleFilter('Все врачи')}
                           className={selectedSpecialization === 'Все врачи' ? 'doctors__selector doctors__selector--active' : 'doctors__selector'}>
                            {language === 'rus' ? 'Все врачи' : 'Всі лікарі'}</p>
                        {filteredDoctors.map(doctor => (
                            <p  onClick={() => handleFilter(doctor.specialization)}
                               className={selectedSpecialization === doctor.specialization ? 'doctors__selector doctors__selector--active' : 'doctors__selector'}
                               key={doctor.id}>{language === 'rus' ? doctor.specialization : doctor.ukrSpecialization}</p>
                        ))}
                    </div>
                    <div className="doctors__list">
                        {filteredDoctorsBySpecialization.map(doctor => (
                            <div key={doctor.id} className="doctors__list-item">
                                <div className="doctors__doctor-avatar-wrapper">
                                    <img className="doctors__doctor-avatar"
                                         src={doctor.avatar} alt="Евролайф"/>
                                </div>
                                <h2 className='doctors__doctor-name'>{language === 'rus' ? doctor.name : doctor.ukrName}</h2>
                                <p className="doctors__doctor-specialization">{language === 'rus' ? doctor.specialization : doctor.ukrSpecialization}</p>
                                <p className="doctors__doctor-category">{language === 'rus' ? doctor.category : doctor.ukrCategory}</p>
                                <p className="doctors__doctor-category">{language === 'rus' ? doctor.secondCategory : doctor.ukrSecondCategory}</p>
                                <p className="doctors__doctor-experience">{language === 'rus' ? `Опыт работы: ${doctor.experience}` : `Досвід роботи: ${doctor.ukrExperience}`}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </StyleRoot>
    )
}